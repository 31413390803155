import React, {
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import clsx from "clsx";
import { isBrowser, isMobile } from "react-device-detect";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import {
  AppBar as MaterialAppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Collapse,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
  TextField,
  ListItemAvatar,
  Popover,
  Breadcrumbs,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  More as MoreIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FolderShared as FolderSharedIcon,
  FolderOpenTwoTone as FolderOpenTwoToneIcon,
  CardTravelTwoTone as CardTravelTwoToneIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  AnnouncementTwoTone as AnnouncementTwoToneIcon,
  CreateTwoTone as CreateTwoToneIcon,
  LocalHospitalTwoTone as LocalHospitalTwoToneIcon,
  ApartmentTwoTone as ApartmentTwoToneIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  HowToReg as HowToRegIcon,
  Eco as EcoIcon,
  FiberManualRecord as Dot,
  AccountTree as AccountTreeIcon,
  Tune as TuneIcon,
  PostAdd as PostAddIcon,
  AllInbox as AllInboxIcon,
  Star as StarIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  People as PeopleIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  AssignmentInd as AssignmentIndIcon,
  MenuBook as MenuBookIcon,
  DescriptionTwoTone as DescriptionTwoToneIcon,
  Search as SearchIcon,
  Link as LinkIcon,
  OpenInNew as OpenInNewIcon,
  FolderSharedTwoTone as FolderSharedTwoToneIcon,
  Favorite as FavoriteIcon,
  ListAlt as ListAltIcon,
  HeadsetMic,
  BurstMode as BurstModeIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { cerrarSesion } from "../../services/auth";
import { MainContext } from "../../App";
import * as Routes from "../../constants/routes";
import * as Permissions from "../../constants/permissions";
import logo_micyd from "../../img/logomicyd.svg";
import ItemMenuMobile from "./components/ItemMenuMobile";
import { analytics, auth } from "../../services/firebase";
import { Autocomplete } from "@material-ui/lab";
import { HashLink as LinkHash } from "react-router-hash-link";
import { ObtenerNoticias } from "./requestNoticias";
import SearchArray from "./components/searchArray.json";
import MasAccesos from "./components/masAccesos";
import "./style.css";
import "./../../App.css";
import DenunciasAccesos from "./components/denunciasAccesos";

export const DrawerContext = createContext({
  setOpenMainDrawer: () => {},
  openMainDrawer: true,
});

export default function AppBar(props) {
  const { usuarioSesion, permisos } = useContext(MainContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  //analytics
  const [claimUser, setClaimUser] = useState(null);
  const loadUsuarioClaims = async () => {
    let resultToken = auth.currentUser
      .getIdTokenResult()
      .then((getIdTokenResult) => {
        setClaimUser(getIdTokenResult.claims);
      });
  };

  const theme = useTheme();
  const [open, setOpen] = useState(isBrowser ? true : false);
  const [openAccesos, setOpenAccesos] = useState(false);
  const [openNoticas, setOpenNoticias] = useState(false);
  const [openAjustes, setOpenAjustes] = useState(false);
  const [openContratosAdjudicados, setOpenContratosAdjudicados] =
    useState(false);
  const [openCydocsAdmin, setCydocsAdmin] = useState(false);
  const [openTalana, setTalana] = useState(false);
  const [openBeneficios, setBeneficios] = useState(false);
  const [itemSelected, setItemSelected] = useState(-1);
  const [SearchText, SetSearchText] = useState("");
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const [SearchValues, SetSearchValues] = useState([]);
  const [anchorElLinkedIn, setAnchorElLinkedIn] = React.useState(null);
  const [anchorAccesos, setAnchorAccesos] = React.useState(null);
  const [anchorCydocs, setAnchorCydocs] = React.useState(null);
  const [anchorReclutamiento, setAnchorReclutamiento] = React.useState(null);
  const [anchorReportar, setAnchorReportar] = React.useState(null);
  const [anchorDenunciasAccesos, setAnchorDenunciasAccesos] = React.useState(null);

  const handleClickLinkedIn = (event) => {
    setAnchorElLinkedIn(event.currentTarget);
  };

  const handleClickAccesos = (event) => {
    setAnchorAccesos(event.currentTarget);
  };

  const handleClickDenunciasAccesos = (event) => {
    setAnchorDenunciasAccesos(event.currentTarget);
  };

  const handleClickCydocs = (event) => {
    setAnchorCydocs(event.currentTarget);
  };

  const handleClickReclutamiento = (event) => {
    setAnchorReclutamiento(event.currentTarget);
  };

  const handleCloseLinkedIn = () => {
    setAnchorElLinkedIn(null);
  };

  const handleCloseAccesos = () => {
    setAnchorAccesos(null);
  };

  const handleCloseDenunciasAccesos = () => {
    setAnchorDenunciasAccesos(null);
  };

  const handleCloseCydocs = () => {
    setAnchorCydocs(null);
  };

  const handleCloseReclutamiento = () => {
    setAnchorReclutamiento(null);
  };

  const openPopover = Boolean(anchorElLinkedIn);
  const openPopoverAccesos = Boolean(anchorAccesos);
  const openPopoverDenunciasAccesos = Boolean(anchorDenunciasAccesos);
  const openPopoverCydocs = Boolean(anchorCydocs);
  const openPopoverReclutamiento = Boolean(anchorReclutamiento);
  const idLinkedIn = openPopover ? "simple-popover" : undefined;
  const idAccesos = openPopoverAccesos ? "simple-popover" : undefined;
  const idCydocs = openPopoverCydocs ? "simple-popover" : undefined;
  const idDenunciasAccesos = openPopoverDenunciasAccesos ? "simple-popover" : undefined;
  const idReclutamiento = openPopoverReclutamiento
    ? "simple-popover"
    : undefined;

  useEffect(() => {
    let values = [];
    values = values.concat(SearchArray);
    ObtenerNoticias(SearchText)
      .then((response) => {
        let noticias = response.data.map((d) => ({
          seccion: d.titulo,
          modulo: "Noticias",
          ruta: `../noticia-ver/${d._id}`,
          link_directo: "Not",
          image: d.adjuntos ? d.adjuntos[0].url : null,
          key_words: [SearchText],
        }));
        values = values.concat(noticias);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        SetSearchValues(values);
      });
  }, [SearchText]);

  const handleSearchText = (event) => {
    SetSearchText(event.target.value);
    setAnchorElSearch(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
    if (openTalana === true) {
      setTalana(false);
    }
    if (openBeneficios === true) {
      setBeneficios(false);
    }
    if (openNoticas === true) {
      setOpenNoticias(false);
    }
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpenAccesos(!openAccesos);
  };
  const handleClickClose = () => {
    setOpenAccesos(false);
  };

  function abrirMenuConAccesos() {
    if (open === false) {
      setOpen(true);
    }
    handleClick();
  }

  function abrirMenuNoticias() {
    if (open === false) {
      setOpen(true);
    }
    setOpenNoticias(!openNoticas);
    setOpenAccesos(false);
    setOpenAjustes(false);
    setOpenContratosAdjudicados(false);
  }

  function abrirCydocsAdmin() {
    if (open === false) {
      setOpen(true);
    }
    setCydocsAdmin(!openCydocsAdmin);
  }

  function abrirTalana() {
    if (open === false) {
      setOpen(true);
    }
    setTalana(!openTalana);
  }

  function abrirMenuAjustes() {
    if (open === false) {
      setOpen(true);
    }
    setOpenAjustes(!openAjustes);
    setOpenNoticias(false);
    setOpenAccesos(false);
    setOpenContratosAdjudicados(false);
  }

  function abrirMenuContratosAdjudicados() {
    if (open === false) {
      setOpen(true);
    }
    setOpenContratosAdjudicados(!openContratosAdjudicados);
    setOpenAjustes(false);
    setOpenNoticias(false);
    setOpenAccesos(false);
  }

  function cerrarMenuConTodo() {
    handleDrawerClose();
    handleClickClose();
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }
  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }
  async function handleCerrarSession() {
    setAnchorEl(null);
    await cerrarSesion();
    handleMobileMenuClose();
  }

  useEffect(() => {
    loadUsuarioClaims();
  }, []);

  const link_directo_search_box = (value) => {
    if (value.link_directo == "No") {
      return (
        <ListItem component={LinkHash} to={value.ruta}>
          <ListItemAvatar>
            {value.image && (
              <Avatar>
                <ChromeReaderModeIcon />
              </Avatar>
            )}
            {!value.image && (
              <Avatar>
                <SearchIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText primary={value.modulo} secondary={value.seccion} />
        </ListItem>
      );
    } else if (value.link_directo == "Not") {
      return (
        <ListItem component={LinkHash} to={value.ruta}>
          <ListItemAvatar>
            {value.image && <Avatar alt="Imagen" src={value.image} />}
            {!value.image && (
              <Avatar>
                <ChromeReaderModeIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText primary={value.modulo} secondary={value.seccion} />
        </ListItem>
      );
    } else {
      return (
        <ListItem component="a" href={value.ruta} target="_blank">
          <ListItemAvatar>
            {value.image && <Avatar alt="Imagen" src={value.image} />}
            {!value.image && (
              <Avatar>
                <LinkIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText primary={value.modulo} secondary={value.seccion} />
        </ListItem>
      );
    }
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton>
            <Avatar alt="Remy Sharp" src={""} className={classes.small} />
          </IconButton>
          <p>Perfil</p>
        </MenuItem>
      </Menu>
    );
  };

  const itemDeMenuPrincipal = () => {
    // return (
    // 	<Breadcrumbs separator="|">
    // 		<Typography color="primary" style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
    // 			<FiberManualRecord style={{ fontSize: "15px", color: "red" }} />
    // 			RIOHS
    // 		</Typography>
    // 		<Typography color="primary" style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
    // 			Web CyD
    // 		</Typography>
    // 		<Typography color="primary" style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
    // 			+Accesos
    // 		</Typography>
    // 		<Typography color="primary" style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
    // 			LinkedIn
    // 		</Typography>
    // 		<Typography color="primary" style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
    // 			Canal de Denuncias
    // 		</Typography>
    // 		<Typography color="primary" style={{ fontSize: "0.75rem", fontWeight: "bold" }}>
    // 			Reportar Cuasi-Accidente
    // 		</Typography>
    // 	</Breadcrumbs>
    // );

    return (
      <Typography
        className={classes.titleNoMargin}
        variant="caption"
        gutterBottom
        color="inherit"
        style={{
          fontSize: "0.75rem",
        }}
      >
        {/* RIOHS */}
        <span
          style={{
            height: "10px",
            width: "10px",
            marginRight: 5,
            backgroundColor: "red",
            borderRadius: "50%",
            display: "inline-block",
          }}
        />
        <a
          href={DOCUMENTOS.RIOHS}
          target="_blank"
          style={{ color: "#731f1f", textDecoration: "none" }}
        >
          <strong>RIOHS</strong>
        </a>
        &nbsp; &nbsp;|&nbsp; &nbsp;
        {/* WEB CYD */}
        <a
          href="https://cydingenieria.cl/"
          target="_blank"
          style={{ color: "#731f1f", textDecoration: "none" }}
          onClick={() => {
            analytics.logEvent("Home_link_superior_WebCyD", {
              usuarioCorreo: claimUser.email,
              usuarioNombre: claimUser.name,
              usuarioContrato: claimUser.proyecto,
            });
          }}
        >
          <strong>Web CyD </strong>
        </a>
        &nbsp; &nbsp;|&nbsp; &nbsp;
        {/* + ACCESOS */}
        <MasAccesos
          handleClickAccesos={handleClickAccesos}
          idAccesos={idAccesos}
          openPopoverAccesos={openPopoverAccesos}
          anchorAccesos={anchorAccesos}
          handleCloseAccesos={handleCloseAccesos}
          permisos={permisos}
          claimUser={claimUser}
          setAnchorAccesos={setAnchorAccesos}
          handleClickCydocs={handleClickCydocs}
          openPopoverCydocs={openPopoverCydocs}
          idCydocs={idCydocs}
          anchorCydocs={anchorCydocs}
          handleCloseCydocs={handleCloseCydocs}
          setAnchorCydocs={setAnchorCydocs}
          setAnchorReclutamiento={setAnchorReclutamiento}
          handleClickReclutamiento={handleClickReclutamiento}
          openPopoverReclutamiento={openPopoverReclutamiento}
          idReclutamiento={idReclutamiento}
          anchorReclutamiento={anchorReclutamiento}
          handleCloseReclutamiento={handleCloseReclutamiento}
        />
        &nbsp; &nbsp;|&nbsp; &nbsp;
        {/* LINKEDIN */}
        <a
          style={{
            color: "#731f1f",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={handleClickLinkedIn}
        >
          <strong>LinkedIn</strong>
        </a>
        <Popover
          id={idLinkedIn}
          open={openPopover}
          anchorEl={anchorElLinkedIn}
          onClose={handleCloseLinkedIn}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Typography style={{ padding: "0.5rem" }}>
            <Button
              component="a"
              href={LINKEDIN_CYD_INGENIERIA}
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
              }}
              onClick={() => {
                analytics.logEvent(
                  "Home_link_superior_LinkedIn_CyD_Ingeniería",
                  {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  }
                );
                setAnchorElLinkedIn(null);
              }}
            >
              <strong>CyD Ingeniería</strong>
            </Button>
            <br />
            <Button
              component="a"
              href={LINKEDIN_CYD_TECNOLOGIA}
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
              }}
              onClick={() => {
                analytics.logEvent(
                  "Home_link_superior_LinkedIn_CyD_Tecnología",
                  {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  }
                );
                setAnchorElLinkedIn(null);
              }}
            >
              <strong>CyD Tecnología</strong>
            </Button>
          </Typography>
        </Popover>
        &nbsp; &nbsp;|&nbsp; &nbsp;
        {/* CANAL DE DENUNCIAS */}

        <DenunciasAccesos
          anchorDenunciasAccesos={anchorDenunciasAccesos}
          handleClickDenunciasAccesos={handleClickDenunciasAccesos}
          openPopoverDenunciasAccesos={openPopoverDenunciasAccesos}
          handleCloseDenunciasAccesos={handleCloseDenunciasAccesos}
          setAnchorDenunciasAccesos={setAnchorDenunciasAccesos}
          idDenunciasAccesos={idDenunciasAccesos}
          claimUser={claimUser}
        />
        {/* <a
          href={CANAL_DENUNCIAS}
          target="_blank"
          style={{
            color: "#731f1f",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            analytics.logEvent(
              "Home_link_superior_Reportar_canal_de_Denuncias",
              {
                usuarioCorreo: claimUser.email,
                usuarioNombre: claimUser.name,
                usuarioContrato: claimUser.proyecto,
              }
            );
            setAnchorReportar(null);
          }}
        > 
           <strong>Canal de Denuncias</strong>
        </a> */}
        &nbsp; &nbsp;|&nbsp; &nbsp;
        {/* CUASIACCIDENTE */}
        <a
          href={FORM_CUASIACCIDENTE}
          target="_blank"
          style={{
            color: "#731f1f",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            analytics.logEvent("Home_link_superior_Reportar_cuasi-accidente", {
              usuarioCorreo: claimUser.email,
              usuarioNombre: claimUser.name,
              usuarioContrato: claimUser.proyecto,
            });
            setAnchorReportar(null);
          }}
        >
          <strong>Reporta Cuasi-Accidentes</strong>
        </a>
      </Typography>
    );
  };

  const itemMenuMobile = () => {
    return <ItemMenuMobile />;
  };

  const GlobalCss = withStyles({
    "@global": {
      ".Mui-selected": {
        borderRadius: "0 25px 25px 0",
      },
      ".MuiListItem-button:hover ": {
        borderRadius: "0 25px 25px 0",
      },
    },
  })(() => null);

  const openSearch = Boolean(anchorElSearch);
  const id = openSearch ? "simple-popover" : undefined;

  const renderAppMobile = () => {
    return (
      <Fragment>
        <GlobalCss />
        <div className={classes.root}>
          <MaterialAppBar
            position="fixed"
            elevation={0}
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
          >
            <Box boxShadow={0}>
              <Toolbar
                variant="dense"
                style={{ backgroundColor: "#FFFFFF", paddingLeft: "18px" }}
              >
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <div>
                  <Link to="/">
                    <img
                      height={isMobile ? "45px" : "70px"}
                      src={LOGO_MI_CYD}
                      alt="CyDocs"
                      onClick={() => {
                        setItemSelected(-1);
                      }}
                    />
                  </Link>
                </div>
                {isBrowser && (
                  <div className={classes.search_div}>
                    <Autocomplete
                      options={SearchValues}
                      noOptionsText="Sin Resultados"
                      getOptionLabel={(option) => SearchText}
                      filterOptions={(options) =>
                        options.filter((sv) =>
                          sv.key_words.some((kw) =>
                            kw.includes(SearchText.toLowerCase())
                          )
                        )
                      }
                      size="small"
                      freeSolo={true}
                      renderInput={(params) => (
                        <TextField
                          className="inputRounded"
                          label={
                            <Typography style={{ fontSize: "10px" }}>
                              {isBrowser ? "Buscar en Mi CyD" : "Buscar"}
                            </Typography>
                          }
                          variant="outlined"
                          onChange={handleSearchText}
                          {...params}
                          style={{ borderRadius: "50px" }}
                        />
                      )}
                      renderOption={(value) => link_directo_search_box(value)}
                      onClose={() => SetSearchText("")}
                    />
                  </div>
                )}
                <div className={classes.grow} />
                <Grid
                  container
                  direction="row"
                  justifyContent={isMobile ? "center" : "flex-end"}
                  alignItems="center"
                  style={{
                    paddingRight: 10,
                  }}
                >
                  {isMobile ? itemMenuMobile() : itemDeMenuPrincipal()}
                </Grid>

                <div className={classes.sectionDesktop}>
                  <Divider
                    orientation="vertical"
                    style={{
                      height: "2rem",
                      marginTop: "1rem",
                    }}
                  />
                  {isBrowser && (
                    <Grid container direction="column">
                      <div className={classes.contenedorUser}>
                        <Grid item xs>
                          <Hidden mdDown>
                            <Typography
                              className={classes.titleNoMargin}
                              variant="body2"
                              gutterBottom
                              color="inherit"
                            >
                              Bienvenido
                            </Typography>
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Box
                                component="div"
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                <Typography
                                  className={classes.titleBold}
                                  variant="body1"
                                  color="inherit"
                                >
                                  {usuarioSesion
                                    ? usuarioSesion.nombre_abreviado
                                    : ""}
                                </Typography>
                              </Box>
                            </div>
                          </Hidden>
                        </Grid>
                      </div>
                    </Grid>
                  )}
                  <IconButton
                    edge="end"
                    aria-label="Account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={usuarioSesion ? usuarioSesion.photoUrl : ""}
                      className={classes.bigAvatar}
                    />
                  </IconButton>
                </div>
              </Toolbar>
            </Box>
          </MaterialAppBar>
          {renderMenu()}
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={cerrarMenuConTodo}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>

            {isMobile && (
              <div className={classes.search_div_mobile}>
                <Autocomplete
                  options={SearchValues}
                  noOptionsText="Sin Resultados"
                  getOptionLabel={(option) => SearchText}
                  filterOptions={(options) =>
                    options.filter((sv) =>
                      sv.key_words.some((kw) =>
                        kw.includes(SearchText.toLowerCase())
                      )
                    )
                  }
                  size="small"
                  freeSolo={true}
                  renderInput={(params) => (
                    <TextField
                      className="inputRounded"
                      label={
                        <Typography style={{ fontSize: "10px" }}>
                          {isBrowser ? "Buscar en Mi CyD" : "Buscar"}
                        </Typography>
                      }
                      variant="outlined"
                      onChange={handleSearchText}
                      {...params}
                      style={{ borderRadius: "50px" }}
                    />
                  )}
                  renderOption={(value) => link_directo_search_box(value)}
                  onClose={() => SetSearchText("")}
                />
              </div>
            )}

            <Divider style={{ marginTop: "0.4rem" }} />

            <List style={{ backgroundColor: "#FFFFFF" }}>
              {/* BIENVENIDA CYD */}
              {/* <Tooltip
								title="Bienvenida CyD"
								placement="right"
								disableHoverListener={open}
							>
								<ListItem
									button
									component={Link}
									to={Routes.BIENVENIDA_CYD}
									selected={itemSelected === 8}
									onClick={() => {
										setItemSelected(8);
										analytics.logEvent("Menú_Lateral_Bienvenida_CyD", {
											usuarioCorreo: claimUser.email,
											usuarioNombre: claimUser.name,
											usuarioContrato: claimUser.proyecto,
										});
									}}
								>
									<ListItemIcon>
										<img
											style={{ marginRight: "20px" }}
											src={"https://drive.google.com/uc?export=view&id=1euHQ1ubIg6_eof-7g3PD8i8wU6serk-r"}
											alt="CyDocs"
											width="25px"
										/>
									</ListItemIcon>
									<ListItemText>
										Bienvenida CyD{" "}
									</ListItemText>
								</ListItem>
							</Tooltip> */}

              <Divider />

              {/* Mesa de Ayuda */}
              <Tooltip
                title="Mesa de Ayuda CyD"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={MESA_AYUDA}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Mesa_Ayuda", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <HeadsetMic style={{ color: "#731f1f" }} />
                  </ListItemIcon>
                  <ListItemText>Mesa de Ayuda CyD</ListItemText>
                </ListItem>
              </Tooltip>

              <Divider />

              {/* NOSOTROS */}
              <Tooltip
                title="Nosotros"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  key={1}
                  button
                  component={Link}
                  to={Routes.NOSOTROS}
                  selected={itemSelected === 1}
                  className={classes.selectItem}
                  onClick={() => {
                    setItemSelected(1);
                    analytics.logEvent("Menú_Lateral_Nosotros", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <PeopleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Nosotros</ListItemText>
                </ListItem>
              </Tooltip>

              {/* ACCESOS | TALANA */}
              <Tooltip
                title="Talana"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  onClick={abrirTalana}
                  className={classes.selectItem}
                >
                  <ListItemIcon>
                    <FolderSharedTwoToneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText style={{ color: "black" }}>Talana</ListItemText>
                  {openTalana ? (
                    <ExpandLessIcon color="primary" />
                  ) : (
                    <ExpandMoreIcon color="primary" />
                  )}
                </ListItem>
              </Tooltip>
              <Collapse in={openTalana} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* ACCESOS | TALANA | INTRODUCCIÓN */}
                  <ListItem
                    button
                    component="a"
                    href={SITES.TALANA}
                    target="_blank"
                    className={classes.selectItem}
                    style={{ paddingLeft: theme.spacing(4) }}
                    onClick={() => {
                      analytics.logEvent("Menú_Lateral_Talana_Intro", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <Dot
                        color="inherit"
                        fontSize="small"
                        style={{ width: "1rem", minWidth: "16px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{ color: "black" }}
                      primaryTypographyProps={{
                        className: classes.drawerItem,
                      }}
                    >
                      Introducción
                      <br /> a Talana
                    </ListItemText>
                    <OpenInNewIcon style={{ color: "lightgray" }} />
                  </ListItem>

                  {/* ACCESOS | TALANA | SITIO WEB */}
                  <ListItem
                    button
                    component="a"
                    href={TALANA_WEB}
                    target="_blank"
                    className={classes.selectItem}
                    style={{ paddingLeft: theme.spacing(4) }}
                    onClick={() => {
                      analytics.logEvent("Menú_Lateral_Talana_Sitio_Web", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <Dot
                        color="inherit"
                        fontSize="small"
                        style={{ width: "1rem", minWidth: "16px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{ color: "black" }}
                      primaryTypographyProps={{
                        className: classes.drawerItem,
                      }}
                    >
                      Versión Web
                    </ListItemText>
                    <OpenInNewIcon style={{ color: "lightgray" }} />
                  </ListItem>
                  {/* ACCESOS | CYDOCS ADMINISTRACIÓN | ADMINISTRACIÓN CDZ */}
                </List>
              </Collapse>

              {/* Site Beneficios */}
              <Tooltip
                title="Beneficios"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={SITES.BENEFICIOS}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Site_Beneficios", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <HowToRegIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Beneficios</ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              {/* Site Capacitaciones */}
              <Tooltip
                title="Capacitaciones"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={SITES.CAPACITACIONES}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Site_Capacitaciones", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <CreateTwoToneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Capacitaciones</ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              {/* ACCESOS | RECLUTAMIENTO */}
              <Tooltip
                title="Capacitaciones"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={SITES.RECLUTAMIENTO}
                  target="_blank"
                  className={classes.selectItem}
                >
                  <ListItemIcon>
                    <SearchIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Reclutamiento</ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              {/* Formatos Corporativos */}
              <Tooltip
                title="Formatos Corporativos"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={FORMATOS_CORPORATIVOS}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Formatos_corporativos", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <DescriptionTwoToneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    Formatos
                    <br /> Corporativos
                  </ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              {/* SGI | SISTEMA DE GESTIÓN INTEGRADO */}
              <Tooltip
                title="Excelencia Operacional"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={SITES.SGI}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_SGI", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <AccountTreeIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    Excelencia <br />
                    Operacional
                  </ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              {/* RSE - Voluntariado | RESPONSABILIDAD SOCIAL EMPRESARIAL */}
              <Tooltip
                title="RSE - Voluntariado"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component={Link}
                  to={Routes.RSE}
                  selected={itemSelected === 7}
                  onClick={() => {
                    setItemSelected(7);
                    analytics.logEvent("Menú_Lateral_RSE", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <EcoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>RSE - Voluntariado</ListItemText>
                </ListItem>
              </Tooltip>

              {/* Comité de Cuidado*/}
              <Tooltip
                title="RSE"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component={Link}
                  to={Routes.COMITECUIDADO}
                  selected={itemSelected === 2}
                  onClick={() => {
                    setItemSelected(2);
                    analytics.logEvent("Menú_Lateral_Comite_Cuidado", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <FavoriteIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    Comité de <br /> Cuidado
                  </ListItemText>
                </ListItem>
              </Tooltip>

              {/* Site Compliance | Prevención del delito */}
              <Tooltip
                title="Prevención de Delitos"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={SITES.COMPLIANCE}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Site_Compliance", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <ApartmentTwoToneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    Prevención <br /> de Delitos
                  </ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              {/* Site COVID */}
              {/* <Tooltip
                title="Site COVID"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={COVID_CYD}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Site_COVID", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <LocalHospitalTwoToneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>COVID</ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip> */}

              {/* BIBLIOTECA */}
              <Tooltip
                title="Biblioteca"
                placement="right"
                disableHoverListener={open}
              >
                <ListItem
                  button
                  component="a"
                  href={SITES.ACADEMY}
                  target="_blank"
                  className={classes.selectItem}
                  onClick={() => {
                    analytics.logEvent("Menú_Lateral_Biblioteca", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <MenuBookIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText style={{ color: "black" }}>
                    Biblioteca
                  </ListItemText>
                  <OpenInNewIcon style={{ color: "lightgray" }} />
                </ListItem>
              </Tooltip>

              <Divider />

              {/* AJUSTES */}
              {permisos && permisos[Permissions.VER_AJUSTES] && (
                <Fragment>
                  <ListItem
                    button
                    onClick={abrirMenuAjustes}
                    selected={itemSelected === 9}
                  >
                    <ListItemIcon>
                      <TuneIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>Ajustes</ListItemText>
                    {openAjustes ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )}
                  </ListItem>
                  <Collapse in={openAjustes} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* AJUSTES | ADMINISTRACIÓN */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.AJUSTES_ADMINISTRACION}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <AssignmentIndIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText style={{ color: "black" }}>
                          Administración
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Collapse>
                </Fragment>
              )}

              {/* NOTICIA */}
              {permisos && permisos[Permissions.VER_AJUSTES_NOTICIA] && (
                <Fragment>
                  <ListItem button onClick={abrirMenuNoticias}>
                    <ListItemIcon>
                      <ChromeReaderModeIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Noticias
                    </ListItemText>
                    {openNoticas ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )}
                  </ListItem>
                  <Collapse in={openNoticas} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* NOTICIAS | AGREGAR */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.NOTICIA_AGREGAR}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <PostAddIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Agregar Noticia
                        </ListItemText>
                      </ListItem>
                      {/* NOTICIAS | TEMA DE INTERES */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.NOTICIA_TEMA_INTERES}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <PostAddIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Agregar Tema
                          <br /> de Interés
                        </ListItemText>
                      </ListItem>
                      {/* NOTICIAS | PUBLICADAS, EN REVISIÓN, PROGRAMADAS */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.MIS_NOTICIAS}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <AllInboxIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Administrador
                          <br />
                          de Noticias
                        </ListItemText>
                      </ListItem>
                      {/* NOTICIAS | REVISORES */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.AJUSTES_CONFIGURACION}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText style={{ color: "black" }}>
                          Revisores
                        </ListItemText>
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to={Routes.IMAGENES_BANNER}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <BurstModeIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText style={{ color: "black" }}>
                          Imágenes Banner
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Collapse>
                </Fragment>
              )}

              {/* AJUSTES */}
              {permisos &&
                permisos[Permissions.AGREGAR_CONTRATO_ADJUDICADO] && (
                  <Fragment>
                    <ListItem
                      button
                      onClick={abrirMenuContratosAdjudicados}
                      selected={itemSelected === 14}
                    >
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        Contratos
                        <br /> Adjudicados
                      </ListItemText>
                      {openContratosAdjudicados ? (
                        <ExpandLessIcon color="primary" />
                      ) : (
                        <ExpandMoreIcon color="primary" />
                      )}
                    </ListItem>
                    <Collapse
                      in={openContratosAdjudicados}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          component={Link}
                          to={Routes.CONTRATOS_ADJUDICADOS}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            <PostAddIcon color="inherit" />
                          </ListItemIcon>
                          <ListItemText style={{ color: "black" }}>
                            Agregar Contrato <br /> Adjudicado
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Collapse>
                  </Fragment>
                )}
            </List>
          </Drawer>
        </div>
      </Fragment>
    );
  };

  const renderApp = () => {
    return (
      <Fragment>
        <div className={classes.root}>
          <MaterialAppBar
            position="fixed"
            elevation={0}
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
          >
            <Box boxShadow={0}>
              <Toolbar
                variant="dense"
                style={{ backgroundColor: "#FFFFFF", paddingLeft: "18px" }}
              >
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <div>
                  <Link to="/">
                    <img
                      height={isMobile ? "45px" : "70px"}
                      src={logo_micyd}
                      alt="CyDocs"
                    />
                  </Link>
                </div>

                <div className={classes.grow} />
                <div
                  position="end"
                  style={{
                    marginTop: "auto",
                    marginBottom: "0.8rem",
                    paddingRight: "2rem",
                  }}
                >
                  {/* LINKS SUPERIORES */}
                  <Typography
                    className={classes.titleNoMargin}
                    variant="body2"
                    gutterBottom
                    color="inherit"
                  >
                    <a
                      component={Link}
                      to="/noticias"
                      variant="contained"
                      color="primary"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}
                      onClick={() => {
                        analytics.logEvent("Home_link_superior_noticias", {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        });
                      }}
                    >
                      <strong>Noticias</strong>
                    </a>
                    &nbsp; &nbsp;|&nbsp; &nbsp;
                    <a
                      href="https://cydingenieria.cl/"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}
                      onClick={() => {
                        analytics.logEvent("Home_link_Web_CyD", {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        });
                      }}
                    >
                      <strong>Web CyD </strong>
                    </a>
                    &nbsp; &nbsp; |&nbsp; &nbsp;
                    <a
                      href="/noticias"
                      style={{ color: "#731f1f", textDecoration: "none" }}
                      onClick={() => {
                        analytics.logEvent("Home_link_superior_Noticias", {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        });
                      }}
                    >
                      <strong>NOTICIAS</strong>
                    </a>
                    &nbsp; &nbsp;|&nbsp; &nbsp;
                    <a
                      href="https://sites.google.com/cydingenieria.cl/plancyd2022"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}
                      onClick={() => {
                        analytics.logEvent("Home_link_superior_Plan_2022", {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        });
                      }}
                    >
                      <strong>PLAN 2022</strong>
                    </a>
                    &nbsp; &nbsp;|&nbsp; &nbsp;
                    <a
                      href="https://www.linkedin.com/company/c-y-d-ingenieria/"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}
                      onClick={() => {
                        analytics.logEvent("Home_link_superior_LinkedIn", {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        });
                      }}
                    >
                      <strong>LinkedIn</strong>
                    </a>
                    &nbsp; &nbsp;|&nbsp; &nbsp;
                    <a
                      href="https://cydingenieria.cl/canal-de-denuncias/"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}
                      onClick={() => {
                        analytics.logEvent("Home_link_Canal_de_Denuncias", {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        });
                      }}
                    >
                      <strong>CANAL DE DENUNCIAS</strong>
                    </a>
                  </Typography>
                </div>
                <div className={classes.sectionDesktop}>
                  <Divider
                    orientation="vertical"
                    style={{
                      height: "2rem",
                      marginTop: "1rem",
                      marginRight: "1rem",
                    }}
                  />

                  <Grid container direction="column">
                    <div className={classes.contenedorUser}>
                      <Grid item xs>
                        <Typography
                          className={classes.titleNoMargin}
                          variant="body2"
                          gutterBottom
                          color="inherit"
                        >
                          Bienvenido
                        </Typography>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <Box
                            component="div"
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >
                            <Typography
                              className={classes.titleBold}
                              variant="body1"
                              color="inherit"
                            >
                              {usuarioSesion ? usuarioSesion.nombre : ""}
                            </Typography>
                          </Box>
                        </div>
                      </Grid>
                    </div>
                  </Grid>

                  <IconButton
                    edge="end"
                    aria-label="Account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={usuarioSesion ? usuarioSesion.photoUrl : ""}
                      className={classes.bigAvatar}
                    />
                  </IconButton>
                </div>
                <div className={clsx(classes.sectionMobile)}>
                  <IconButton
                    aria-label="Show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              </Toolbar>
            </Box>
          </MaterialAppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={cerrarMenuConTodo}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>

            <Divider />

            <List style={{ backgroundColor: "#FFFFFF" }}>
              {/* ACCESOS */}

              <ListItem button onClick={abrirMenuConAccesos}>
                <ListItemIcon>
                  <StarIcon style={{ color: "#0d47a1" }} />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>Accesos</ListItemText>
                {openAccesos ? (
                  <ExpandLessIcon color="primary" />
                ) : (
                  <ExpandMoreIcon color="primary" />
                )}
              </ListItem>

              <Collapse in={openAccesos} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* ACCESOS | PARA GERENTES */}
                  {permisos && permisos[Permissions.VER_ACCESOS_GERENTES] && (
                    <Fragment>
                      <Tooltip
                        title="Nosotros"
                        placement="right"
                        disableHoverListener={open}
                      >
                        <ListItem
                          button
                          onClick={() => {
                            analytics.logEvent("Menu_lateral_gerentes", {
                              usuarioCorreo: claimUser.email,
                              usuarioNombre: claimUser.name,
                              usuarioContrato: claimUser.proyecto,
                            });
                          }}
                          component={Link}
                          to={Routes.GERENTES}
                          className={classes.selectItem}
                          selected={itemSelected === 2}
                          style={{ paddingLeft: theme.spacing(4) }}
                        >
                          <ListItemIcon>
                            <CardTravelTwoToneIcon color="inherit" />
                          </ListItemIcon>
                          <ListItemText>Para Gerentes</ListItemText>
                        </ListItem>
                      </Tooltip>
                    </Fragment>
                  )}

                  {/* ACCESOS | TALANA */}
                  <ListItem
                    button
                    component="a"
                    href="https://sites.google.com/cydingenieria.cl/talana"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent("Menu_lateral_Talana", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <FolderSharedIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Talana
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | CYDOCS ADMINISTRACIÓN */}
                  <ListItem
                    button
                    onClick={abrirCydocsAdmin}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <FolderOpenTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      CyDocs
                      <br />
                      Administración
                      {openCydocsAdmin ? (
                        <ExpandLessIcon color="primary" />
                      ) : (
                        <ExpandMoreIcon color="primary" />
                      )}
                    </ListItemText>
                  </ListItem>
                  <Collapse in={openCydocsAdmin} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* ACCESOS | CYDOCS ADMINISTRACIÓN | PORTAFOLIO */}
                      <ListItem
                        button
                        component="a"
                        href="https://portafolio.cydocs.cl/"
                        target="_blank"
                        className={classes.nested2}
                        onClick={() => {
                          analytics.logEvent("Menu_lateral_cydocs_portafolio", {
                            usuarioCorreo: claimUser.email,
                            usuarioNombre: claimUser.name,
                            usuarioContrato: claimUser.proyecto,
                          });
                        }}
                      >
                        <ListItemText
                          style={{ color: "black" }}
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Portafolio
                        </ListItemText>
                      </ListItem>
                      {/* ACCESOS | CYDOCS ADMINISTRACIÓN | ADMINISTRACIÓN CYD */}
                      <ListItem
                        button
                        component="a"
                        href="https://administracion.cydocs.cl/"
                        target="_blank"
                        className={classes.nested2}
                        onClick={() => {
                          analytics.logEvent("Menu_lateral_cydocs_admin_CyD", {
                            usuarioCorreo: claimUser.email,
                            usuarioNombre: claimUser.name,
                            usuarioContrato: claimUser.proyecto,
                          });
                        }}
                      >
                        <ListItemText
                          style={{ color: "black" }}
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Administración <br /> CyD
                        </ListItemText>
                      </ListItem>
                      {/* ACCESOS | CYDOCS ADMINISTRACIÓN | ADMINISTRACIÓN CDZ */}
                      <ListItem
                        button
                        component="a"
                        href="https://script.google.com/a/macros/cydingenieria.cl/s/AKfycbzBb4gDIQl7OgbTvHgzFLL_62o6RJYmlitD3blsKgJ-oOI6ILnO/exec?csrfToken=dS6WgO3l-pp4tZoA6O1iyK4F4lSuEyzC-B3w"
                        target="_blank"
                        className={classes.nested2}
                        onClick={() => {
                          analytics.logEvent("Menu_lateral_cydocs_admin_CDZ", {
                            usuarioCorreo: claimUser.email,
                            usuarioNombre: claimUser.name,
                            usuarioContrato: claimUser.proyecto,
                          });
                        }}
                      >
                        <ListItemText
                          style={{ color: "black" }}
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Administración
                          <br /> CDZ
                        </ListItemText>
                      </ListItem>
                      {/* ACCESOS | CYDOCS ADMINISTRACIÓN | SISTEMA DE ADQUISICIONES */}
                      <ListItem
                        button
                        component="a"
                        href="https://sda.cydocs.cl/"
                        target="_blank"
                        className={classes.nested2}
                        onClick={() => {
                          analytics.logEvent(
                            "Menu_lateral_cydocs_sistema_adquisiciones",
                            {
                              usuarioCorreo: claimUser.email,
                              usuarioNombre: claimUser.name,
                              usuarioContrato: claimUser.proyecto,
                            }
                          );
                        }}
                      >
                        <ListItemText
                          style={{ color: "black" }}
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Sistema de <br />
                          Adquisiciones
                        </ListItemText>
                      </ListItem>
                      {/* ACCESOS | CYDOCS ADMINISTRACIÓN | GESTOR DE ACTIVOS */}
                      <ListItem
                        button
                        component="a"
                        href="https://activos.cydocs.cl/"
                        target="_blank"
                        className={classes.nested2}
                        onClick={() => {
                          analytics.logEvent(
                            "Menu_lateral_cydocs_gestor_activos",
                            {
                              usuarioCorreo: claimUser.email,
                              usuarioNombre: claimUser.name,
                              usuarioContrato: claimUser.proyecto,
                            }
                          );
                        }}
                      >
                        <ListItemText
                          style={{ color: "black" }}
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Gestor de Activos
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Collapse>

                  {/* ACCESOS | FORMATOS CORPORATIVOS */}
                  <ListItem
                    button
                    component="a"
                    href="https://drive.google.com/drive/u/0/folders/0AAvhz91idy1aUk9PVA"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent("Menu_lateral_Formatos_corporativos", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <DescriptionTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Formatos Corporativos
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | RECLUTAMIENTO */}
                  <ListItem
                    button
                    component="a"
                    href="https://sites.google.com/cydingenieria.cl/site-reclutamiento-y-seleccion/"
                    target="_blank"
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <SearchIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Reclutamiento
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | SITE CAPACITACIONES */}
                  <ListItem
                    button
                    component="a"
                    href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent("Menu_lateral_site_capacitaciones", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <CreateTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Site
                      <br />
                      Capacitaciones
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | REPORTA CUASI-ACCIDENTES */}
                  <ListItem
                    button
                    component="a"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScas67IjKmML3FSUovKetPrh9Wm83kmuxXncaPkhZu7xPb90Q/viewform"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent(
                        "Menu_lateral_reporta_cuasi_accidente",
                        {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        }
                      );
                    }}
                  >
                    <ListItemIcon>
                      <AnnouncementTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Reporta
                      <br />
                      Cuasi-Accidente
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | SITE COVID */}
                  <ListItem
                    button
                    component="a"
                    href="https://covid19.cydingenieria.cl/"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent("Menu_lateral_site_covid", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <LocalHospitalTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Site COVID
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | SITE COMPLIANCE */}
                  <ListItem
                    button
                    component="a"
                    href="https://sites.google.com/cydingenieria.cl/compliance/"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent("Menu_lateral_site_compliance", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <ApartmentTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Prevención de Delitos
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | ACCESO PROYECTOS */}
                  <ListItem
                    button
                    component="a"
                    href="http://201.238.203.174:5501/"
                    target="_blank"
                    className={classes.nested}
                    onClick={() => {
                      analytics.logEvent("Menu_lateral_acceso_proyectos", {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Acceso
                      <br />
                      Proyectos{" "}
                    </ListItemText>
                  </ListItem>

                  {/* ACCESOS | VOLUNTARIADO CYD */}
                  {/* <ListItem
                    button
                    component="a"
                    href=""
                    target="_blank"
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <EmojiFlagsTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Voluntariado
                      <br />
                      CyD
                    </ListItemText>
                  </ListItem> */}

                  {/* ACCESOS | CYDOCS */}
                  {/* <ListItem
                    button
                    component="a"
                    href="https://www.cydocs.cl/"
                    target="_blank"
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <FolderOpenTwoToneIcon color="inherit" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      CYDOCS
                    </ListItemText>
                  </ListItem> */}
                </List>
              </Collapse>

              <Divider />

              {/* NOSOTROS */}

              <Tooltip title="Add" placement="right">
                <ListItem
                  button
                  component={Link}
                  to={Routes.NOSOTROS}
                  onClick={() => {
                    analytics.logEvent("Menu_lateral_Nosotros", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                  }}
                >
                  <ListItemIcon>
                    <PeopleIcon color="primary" />
                  </ListItemIcon>

                  <ListItemText style={{ color: "black" }}>
                    Nosotros
                  </ListItemText>
                </ListItem>
              </Tooltip>

              {/* BENEFICIOS */}
              <ListItem
                button
                component={Link}
                to={Routes.BENEFICIOS}
                onClick={() => {
                  analytics.logEvent("Menu_lateral_Beneficios", {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  });
                }}
              >
                <ListItemIcon>
                  <HowToRegIcon color="primary" />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                  Beneficios
                </ListItemText>
              </ListItem>

              {/* FORMACIÓN Y DESARROLLO */}
              {/* <ListItem
								button
								component={Link}
								to={Routes.FORMACIONDESARROLLO}
								onClick={() => {
									analytics.logEvent("Menu_lateral_F&D", {
										usuarioCorreo: claimUser.email,
										usuarioNombre: claimUser.name,
										usuarioContrato: claimUser.proyecto,
									});
								}}
							>
								<ListItemIcon>
									<FlagIcon color="primary" />
								</ListItemIcon>
								<ListItemText style={{ color: "black" }}>
									Formación y<br />
									Desarrollo
								</ListItemText>
							</ListItem> */}

              {/* SGI | SISTEMA DE GESTIÓN INTEGRADO */}
              <ListItem
                button
                component="a"
                href="https://sites.google.com/cydingenieria.cl/sgi"
                target="_blank"
                style={{ padding: 8, paddingLeft: "1rem" }}
                onClick={() => {
                  analytics.logEvent("Menu_lateral_SGI", {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  });
                }}
              >
                <ListItemIcon>
                  <AccountTreeIcon color="primary" />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>SGI</ListItemText>
              </ListItem>

              {/* BIBLIOTECA */}
              <ListItem
                button
                component="a"
                href="https://sites.google.com/cydingenieria.cl/cyd-academy/inicio"
                target="_blank"
                style={{ padding: 8, paddingLeft: "1rem" }}
              >
                <ListItemIcon>
                  <MenuBookIcon color="primary" />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                  Biblioteca
                </ListItemText>
              </ListItem>

              {/* RSE | RESPONSABILIDAD SOCIAL EMPRESARIAL */}
              <ListItem
                button
                component={Link}
                to={Routes.RSE}
                onClick={() => {
                  analytics.logEvent("Menu_lateral_RSE", {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  });
                }}
              >
                <ListItemIcon>
                  <EcoIcon color="primary" />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>RSE</ListItemText>
              </ListItem>

              <Divider />

              {/* BIENVENIDA CYD */}
              <ListItem
                button
                component={Link}
                to={Routes.BIENVENIDA_CYD}
                onClick={() => {
                  analytics.logEvent("Menu_lateral_Bienvenida_CyD", {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  });
                }}
              >
                <ListItemIcon style={{ paddingLeft: "10px" }}>
                  <img
                    src={
                      "https://drive.google.com/uc?export=view&id=1euHQ1ubIg6_eof-7g3PD8i8wU6serk-r"
                    }
                    alt="CyDocs"
                    width="30px"
                  />
                </ListItemIcon>
                <ListItemText style={{ color: "black" }}>
                  Bienvenida CyD{" "}
                </ListItemText>
              </ListItem>

              <Divider />

              {/* AJUSTES */}
              {permisos && permisos[Permissions.VER_AJUSTES] && (
                <Fragment>
                  <ListItem button onClick={abrirMenuAjustes}>
                    <ListItemIcon>
                      <TuneIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "#151D25" }}>
                      Ajustes
                    </ListItemText>
                    {openAjustes ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )}
                  </ListItem>
                  <Collapse in={openAjustes} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* AJUSTES | ADMINISTRACIÓN */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.AJUSTES_ADMINISTRACION}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <AssignmentIndIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText style={{ color: "black" }}>
                          Administración
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Collapse>
                </Fragment>
              )}

              {/* NOTICIA */}
              {permisos && permisos[Permissions.VER_AJUSTES_NOTICIA] && (
                <Fragment>
                  <ListItem button onClick={abrirMenuNoticias}>
                    <ListItemIcon>
                      <ChromeReaderModeIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText style={{ color: "black" }}>
                      Noticias
                    </ListItemText>
                    {openNoticas ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )}
                  </ListItem>
                  <Collapse in={openNoticas} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* NOTICIAS | AGREGAR */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.NOTICIA_AGREGAR}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <PostAddIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Agregar Noticia
                        </ListItemText>
                      </ListItem>
                      {/* NOTICIAS | PUBLICADAS, EN REVISIÓN, PROGRAMADAS */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.MIS_NOTICIAS}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <AllInboxIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.drawerItem,
                          }}
                        >
                          Administrador
                          <br />
                          de Noticias
                        </ListItemText>
                      </ListItem>
                      {/* NOTICIAS | REVISORES */}
                      <ListItem
                        button
                        component={Link}
                        to={Routes.AJUSTES_CONFIGURACION}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText style={{ color: "black" }}>
                          Revisores
                        </ListItemText>
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to={Routes.IMAGENES_BANNER}
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <BurstModeIcon color="inherit" />
                        </ListItemIcon>
                        <ListItemText style={{ color: "black" }}>
                          Imágenes Banner
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Collapse>
                </Fragment>
              )}

              {/* AJUSTES */}
              {permisos &&
                permisos[Permissions.AGREGAR_CONTRATO_ADJUDICADO] && (
                  <Fragment>
                    <ListItem
                      button
                      onClick={abrirMenuContratosAdjudicados}
                      selected={itemSelected === 14}
                    >
                      <ListItemIcon>
                        <ListAltIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        Contratos
                        <br /> Adjudicados
                      </ListItemText>
                      {openContratosAdjudicados ? (
                        <ExpandLessIcon color="primary" />
                      ) : (
                        <ExpandMoreIcon color="primary" />
                      )}
                    </ListItem>
                    <Collapse
                      in={openContratosAdjudicados}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          component={Link}
                          to={Routes.CONTRATOS_ADJUDICADOS}
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            <PostAddIcon color="inherit" />
                          </ListItemIcon>
                          <ListItemText style={{ color: "black" }}>
                            Agregar Contrato <br /> Adjudicado
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Collapse>
                  </Fragment>
                )}
            </List>
          </Drawer>
          {renderMobileMenu()}
          {renderMenu()}
        </div>
      </Fragment>
    );
  };

  return renderAppMobile();
}

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  listScroll: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100%px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: "#FFFFFF",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#FFFFFF",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: isMobile ? 0 : theme.spacing(7) + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested2: {
    paddingLeft: theme.spacing(8),
  },
  linksSecundarios: {
    padding: 8,
  },
  infoButton: {
    marginLeft: theme.spacing(2),
  },
  sectionDesktop: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  contenedorUser: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "20px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  titleNoMargin: {
    margin: 0,
    textAlign: "end",
    color: "black",
  },
  titleBold: {
    color: "black",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  hide: {
    display: "none",
  },
  button: {
    zIndex: theme.zIndex.drawer + 2,
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  homeLink: {
    color: "white",
    textDecoration: "none",
  },
  img: {
    height: "40px",
    marginTop: "12px",
    marginBottom: "12px",
    marginRight: theme.spacing(2),
  },
  cyd_div: {
    height: "40px",
    backgroundColor: "#fff",
    marginTop: "12px",
    marginBottom: "12px",
    paddingTop: "5px",
    borderRadius: "20px",
  },
  img_cyd: {
    height: "70px",
  },
  selectItem: {
    color: "primary",
  },
  search_div: {
    marginLeft: theme.spacing(2),
    width: "30%",
  },
  search_div_mobile: {
    marginLeft: theme.spacing(2),
    marginTop: "1rem",
    width: "80%",
  },
}));

const LOGO_MI_CYD = "https://storage.googleapis.com/mi-cyd/home/logomicyd.png";
const LINKEDIN_CYD_INGENIERIA =
  "https://www.linkedin.com/company/c-y-d-ingenieria";
const LINKEDIN_CYD_TECNOLOGIA =
  "https://www.linkedin.com/company/c-y-d-tecnologia/";
const CANAL_DENUNCIAS = "https://cydingenieria.cl/canal-de-denuncias/";
const MESA_AYUDA = "https://soportecyd.sd.cloud.invgate.net/";
const FORM_CUASIACCIDENTE =
  "https://docs.google.com/forms/d/e/1FAIpQLScas67IjKmML3FSUovKetPrh9Wm83kmuxXncaPkhZu7xPb90Q/viewform";
const TALANA_WEB =
  "https://talana.com/es/remuneraciones/login-vue?next=/es/remuneraciones/#/";
const FORMATOS_CORPORATIVOS =
  "https://drive.google.com/drive/u/0/folders/0AAvhz91idy1aUk9PVA";
const COVID_CYD = "https://covid19.cydingenieria.cl/";
const DOCUMENTOS = {
  RIOHS:
    "https://storage.googleapis.com/mi-cyd/home/RIOHS%20CyD%20Ingenier%C3%ADa%202023%20(Mi%20CyD).pdf",
};
const SITES = {
  RECLUTAMIENTO:
    "https://sites.google.com/cydingenieria.cl/site-reclutamiento-y-seleccion/inicio",
  TALANA: "https://sites.google.com/cydingenieria.cl/talana",
  BENEFICIOS: "https://sites.google.com/cydingenieria.cl/site-de-beneficios",
  CAPACITACIONES:
    "https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/",
  SGI: "https://sites.google.com/cydingenieria.cl/siteexcelenciaoperacional",
  COMPLIANCE: "https://sites.google.com/cydingenieria.cl/compliance/",
  ACADEMY: "https://sites.google.com/cydingenieria.cl/cyd-academy/inicio",
};
