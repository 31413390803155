import React, { Fragment } from "react";
import { Button, Popover } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { analytics } from "../../../services/firebase";
import "../style.css";
import "./../../../App.css";

export default function DenunciasAccesos(props) {
  const {
    anchorDenunciasAccesos,
    handleClickDenunciasAccesos,
    openPopoverDenunciasAccesos,
    handleCloseDenunciasAccesos,
    setAnchorDenunciasAccesos,
    idDenunciasAccesos,
    claimUser,    
  } = props;

  return (
    <Fragment>
      <a
        onClick={handleClickDenunciasAccesos}
        style={{ color: "#731f1f", textDecoration: "none", cursor: "pointer" }}
      >
        <strong>Canal de Denuncias</strong>
      </a>
      <Popover
        id={idDenunciasAccesos}
        open={openPopoverDenunciasAccesos}
        anchorEl={anchorDenunciasAccesos}
        onClose={handleCloseDenunciasAccesos}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div style={{ margin: 10 }}>
          <div>
            <Button
              component="a"
              href="https://docs.google.com/forms/d/e/1FAIpQLSd9njeBsKHKnV2KQ-jqka_RzblWt-Ede5n-D3MiV_hVO8Pgjw/viewform?usp=sf_link"
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
                width: "85%",
                justifyContent: "left",
              }}
              onClick={() => {
                analytics.logEvent(
                  "Home_link_superior_Reportar_canal_de_Denuncias",
                  {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  }
                );
                setAnchorDenunciasAccesos(null);
              }}
            >
              <strong>
                Denuncias por Acoso Sexual, <br />
                Laboral y Violencia en el Trabajo
              </strong>
            </Button>
            <OpenInNew
              style={{ color: "lightgray", verticalAlign: "middle" }}
            />
          </div>

          <div>
            <Button
              component="a"
              href={CANAL_DENUNCIAS}
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
                width: "85%",
                justifyContent: "left",
              }}
              onClick={() => {
                analytics.logEvent(
                  "Home_link_superior_Reportar_canal_de_Denuncias",
                  {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  }
                );
              }}
            >
              <strong>Otras denuncias</strong>
            </Button>
            <OpenInNew
              style={{ color: "lightgray", verticalAlign: "middle" }}
            />
          </div>
        </div>
      </Popover>
    </Fragment>
  );
}

const CANAL_DENUNCIAS =
  "https://docs.google.com/forms/d/e/1FAIpQLSdiAXU6tsucBe9JEdIm8Kfljwzbh2GcoXmAeqmaVCD8MtllEg/viewform";
