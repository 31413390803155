import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Backdrop, Fade, Modal, Paper, Box } from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  acordion_: {
    textAlign: "left",
    marginBottom: "1rem",
    paddingLeft: "3rem",
    borderRadius: "20px",
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle2: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    // overflow: "scroll",
    height: "auto",
    width: "70%",
    display: "block",
    margin: "auto",
    cursor: "grab",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "auto",
    // display: "block",
    margin: "auto",
    // cursor: "grab",
  },

  logrosypremios: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    height: "auto",
  },

  gridList: {
    width: "100%",
    height: "100%",
  },
}));

function GalleryVoluntariado(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalVideo, setOpenModalVideo] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);
  const [pptRuta, setPptRuta] = React.useState(null);
  var items = [
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/1.jpg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/2.jpg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/3.jpg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/4.jpg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/5.jpg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/6.jpg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/7.jpeg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/8.jpeg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/9.jpeg",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/10.png",
      video: "No",
    },
    {
      imagen: "https://storage.googleapis.com/mi-cyd/rse/11.JPG",
      video: "No",
    },
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/rse/VOLUNTARIADO.png",
      video: "Si",
    },
  ];

  const handleVideo = () => {
    setPptRuta(
      "https://drive.google.com/file/d/10aYPNN0IavxvsQ6V4sywkibTODIg6yr6/preview"
    );
    // analytics.logEvent("nosotros_politicas_personas", {
    //   usuarioCorreo: claimUser.email,
    //   usuarioNombre: claimUser.name,
    //   usuarioContrato: claimUser.proyecto,
    // });

    setOpenModalVideo(true);
  };

  const handleCloseModalVideo = () => {
    setOpenModalVideo(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalVideo = () => {
    setOpenModalVideo(true);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const abrirImagen = (rutaImagen) => {
    setPptRuta(rutaImagen);
    setOpenModalPpt(true);
  };

  function imagenVideo(props) {
    const UrlMedia = props.url;
    const isVideo = props.isVideo;

    if (isVideo == "No") {
      console.log(UrlMedia);
      console.log(isVideo);
      return (
        <Box style={{ textAlign: "center" }}>
          <a href="#" onClick={() => abrirImagen(UrlMedia)}>
            <img src={UrlMedia} style={{ height: "480px" }}></img>
          </a>
        </Box>
      );
    } else {
      console.log(UrlMedia);
      console.log(isVideo);
      return (
        <Box style={{ textAlign: "center" }}>
          <a href="#" onClick={() => handleVideo(UrlMedia)}>
            <img src={UrlMedia} style={{ height: "480px" }}></img>
          </a>
        </Box>
      );
    }
  }

  return (
    <React.Fragment>
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        fullHeightHover={true}
      >
        {items.map((item, i) => {
          return item.video == "No" ? (
            <Box style={{ textAlign: "center" }}>
              <a href="#" onClick={() => abrirImagen(item.imagen)}>
                <img src={item.imagen} style={{ height: "480px" }}></img>
              </a>
            </Box>
          ) : (
            <Box style={{ textAlign: "center" }}>
              <img
                src={item.imagen}
                alt="Video Voluntariado"
                width="100%"
                style={{ border: "groove", cursor: "pointer" }}
                onClick={handleVideo}
              />
              {/* <iframe src="https://drive.google.com/file/d/10aYPNN0IavxvsQ6V4sywkibTODIg6yr6/preview" width="600" height="400"></iframe> */}
            </Box>
          );
        })}
      </Carousel>

      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModalPpt}
        // overflow="scroll"
        onClose={handleCloseModalPpt}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPpt} onClick={handleCloseModal}>
          <div>
            <Box
              // className={classes.paper}
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                // bgcolor:"lightgreen",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={pptRuta}
                style={{
                  marginTop: "20px",
                  height: "-webkit-fill-available",
                  border: "groove",
                  borderRadius: "2%",
                }}
                onClick={handleCloseModalPpt}
              ></img>
            </Box>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "20%",
                left: "65%",
                top: "82%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >

              <Button
                variant="contained"
                color="Primary"
                onClick={handleCloseModalPpt}
                style={{ fontSize: "1rem", color: "#731f1f", backgroundColor: "white" }}
              >
                <strong>Cerrar</strong>
              </Button>
            </Box>
           
          </div>
        </Fade>
      </Modal>

      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle2}
        open={openModalVideo}
        overflow="scroll"
        onClose={handleCloseModalVideo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalVideo}>
          <div
          // className={classes.paper}
          // style={{ minHeight: "-webkit-fill-available" }}
          >
            <Box style={{ alignSelf: "center", marginTop: "70px" }}>

              {/* <Box style={{paddingTop:"4rem"}}> */}
              <iframe
                style={{ align: "middle" }}
                src={pptRuta}
                // position="fixed"
                frameborder="0"
                width="900px"
                height="500px"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              ></iframe>
            </Box>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "20%",
                left: "65%",
                top: "82%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >

              <Button
                variant="contained"
                color="Primary"
                onClick={handleCloseModalVideo}
                style={{ fontSize: "1rem", color: "#731f1f", backgroundColor: "white" }}
              >
                <strong>Cerrar</strong>
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

    </React.Fragment>
  );
}

export default GalleryVoluntariado;
