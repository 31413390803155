import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { Parallax } from "react-parallax";
import "../../App.css";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { analytics, auth } from "../../services/firebase";
import PDFReader from "../Nosotros/PDFReader";
import GalleryCarousel from "../RSE/GalleryVoluntariado";
import { motion } from "framer-motion";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const image1 =
  "https://drive.google.com/uc?export=view&id=1DicWtB5MEhmRJzRNo6Di5vwowlSq1s2K";
const indicadores =
  "https://drive.google.com/uc?export=view&id=17jtpsAuS3j0B_d5wbTkAkoxP1KZmR6c9";
const documentos =
  "https://drive.google.com/uc?export=view&id=1aNAEevNPTuxbRDjj0Gk01fmxcHDr9Ztv";

const hd_cristo =
  "https://drive.google.com/uc?export=view&id=1UBKd3ib-2hqWCYtcJD6IqrcIoQTiH_8u";
const voluntariado_logo =
  "https://storage.googleapis.com/mi-cyd/Voluntariado/Logo%20Voluntariado%20Corporativo%20CyD.png";
const numeros_rse =
  "https://drive.google.com/uc?export=view&id=19B8SzrVnXPWI6w5mi-3fGpdZXtGRSZS4";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",
    cursor: "grab",
  },
}));

const titulos = createTheme();

titulos.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.7rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "2.3rem",
  },
  fontFamily: "Poppins",
};

titulos.typography.h4 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1.2rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  fontFamily: "Poppins",
};

titulos.typography.h5 = {
  fontSize: "0.5rem",
  "@media (min-width:600px)": {
    fontSize: "0.9rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "1.3rem",
  },
  fontFamily: "Poppins",
  fontWeight: "100",
};

titulos.typography.h6 = {
  fontSize: "0.5rem",
  "@media (min-width:600px)": {
    fontSize: "0.7rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  fontFamily: "Poppins",
  fontWeight: "100",
};

export default function Bienvenida() {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [pdfRuta, setPDFRuta] = React.useState(null);
  const [pptRuta, setPptRuta] = React.useState(null);

  const [openModalVideo, setOpenModalVideo] = React.useState(false);

  //analytics
  const [claimUser, setClaimUser] = useState(null);
  const loadUsuarioClaims = async () => {
    let resultToken = auth.currentUser
      .getIdTokenResult()
      .then((getIdTokenResult) => {
        // console.log(getIdTokenResult);
        setClaimUser(getIdTokenResult.claims);
      });
  };

  const [descargaBoton, setDescargaBoton] = React.useState(false);
  const [pptRutaDescarga, setPptRutaDescarga] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setDescargaBoton(false);
  };
  const handleOpenModalPpt = () => {
    setOpenModalPpt(true);
  };

  const handleOpenModalVideo = () => {
    setOpenModalVideo(true);
  };

  const handleCloseModalVideo = () => {
    setOpenModalVideo(false);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlePdfSustentabilidad = () => {
    setPptRuta(
      "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Desarrollo%20Sustentable%20CyD%20Ingenier%C3%ADa%202021.pdf"
    );
    // chaoseuon(true);
    analytics.logEvent("rse_politica_sustentabilidad", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
    handleOpenModalPpt();
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Apertura de PDFs
  const handlePdfInclusion = () => {
    setPptRuta(
      "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Inclusi%C3%B3n%20CyD%20Ingenier%C3%ADa%202021.pdf"
    );
    // setPptRutaDescarga(
    //   "https://drive.google.com/uc?id=1t4284IKPmNjoc66zbSBwIQF2xy1PyvKX&export=download"
    // );
    // setDescargaBoton(true);
    analytics.logEvent("rse_politica_inclusion_laboral", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
    handleOpenModalPpt();
  };

  React.useEffect(() => {
    loadUsuarioClaims();
    analytics.setCurrentScreen("/rse", { idUsuario: "PENDIENTE" });
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Grid
        style={{
          marginLeft: 14,
          marginRight: 14,
        }}
      >
        <Parallax
          bgImage={image1}
          strength={150}
          style={{
            borderRadius: "20px",
            zIndex: 2,
            marginRight: "1rem",
            marginBottom: "2rem",
            width: "100%",
          }}
        >
          <div
            variant="box"
            style={{
              marginRight: "1rem",
              marginBottom: "1rem",
              height: "auto",
              overflow: "hidden",
              height: "17rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "20px",
                marginLeft: "2rem",
                zIndex: 10,
              }}
            >
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{
                  color: "white",
                }}
              ></Typography>
              <Typography variant="h3" style={{ color: "white" }}>
                <strong>RSE</strong>
              </Typography>
            </div>
          </div>
        </Parallax>
        <div id="galeria"></div>

        {/* Introducción RSE*/}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: {
              scale: 0.8,
              opacity: 0,
              y: 100,
            },
            visible: {
              scale: 1,
              opacity: 1,
              y: 0,
              transition: {
                delay: 0.5,
              },
            },
          }}
        >
          <Paper
            variant="outlined"
            style={{
              borderRadius: "20px",
              textAlign: "-webkit-center",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Box m={3}>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={7} style={{ padding: "1rem", marginTop: "1rem" }}>
                      <Typography
                        variant="h6"
                        align="justify"
                        style={{ textAlign: "left" }}
                      >
                        <img
                          src={voluntariado_logo}
                          alt="Indicadores"
                          width="100%"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ padding: "1rem" }}>
                      <Typography
                        variant="h6"
                        align="justify"
                        style={{ textAlign: "left" }}
                      >
                        <img src={hd_cristo} alt="Indicadores" width="100%" />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ padding: "1rem", marginTop: "3rem" }}
                    >
                      <Typography
                        variant="h6"
                        align="justify"
                        style={{ textAlign: "center" }}
                      >
                        <img src={numeros_rse} alt="Indicadores" width="80%" />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    // style={{ padding: "1rem", marginTop: "rem" }}
                    >
                      <Typography
                        variant="h6"
                        align="justify"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          component="a"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSdTUArJnM897L7U8efamxNfYF5dmf5HAtyIFnNg4JLIBgwcMw/viewform"
                          target="_blank"
                          variant="contained"
                          color="primary"
                        >
                          Inscripción Socios del Hogar de Cristo
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ alignSelf: "center" }}>
                  <Box style={{ display: "-ms-flexbox", maxWidth: "50.5vw" }}>
                    <GalleryCarousel />
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Paper>
        </motion.div>        

        {/* Modals de PDF */}
        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModal}
          overflow="scroll"
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classes.paper}>
              <Paper>
                <PDFReader direccionPDF={pdfRuta}></PDFReader>
              </Paper>
              <Box
                zIndex="tooltip"
                style={{
                  position: "fixed",
                  width: "69%",
                  left: "15%",
                  top: "90%",
                  height: "3rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                {descargaBoton == true && (
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleCloseModalPpt}
                    href={pptRutaDescarga}
                    style={{ marginRight: "1rem" }}
                  >
                    Descargar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModalPpt}
          overflow="scroll"
          onClose={handleCloseModalPpt}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModalPpt}>
            <div
              className={classes.paper}
              style={{ minHeight: "-webkit-fill-available" }}
            >
              <Paper
                style={{ minWidth: "-webkit-fill-available", height: "67vh" }}
              >
                {/* <Box style={{paddingTop:"4rem"}}> */}
                <iframe
                  style={{ align: "middle" }}
                  src={pptRuta}
                  // position="fixed"
                  frameborder="0"
                  width="100%"
                  height="100%"
                  allowfullscreen="true"
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                ></iframe>
                {/* </Box> */}
              </Paper>
              <Box
                zIndex="tooltip"
                style={{
                  position: "fixed",
                  width: "20%",
                  left: "70%",
                  top: "90%",
                  height: "3rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                {descargaBoton == true && (
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleCloseModalPpt}
                    href={pptRutaDescarga}
                    style={{ marginRight: "1rem" }}
                  >
                    Descargar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModalPpt}
                >
                  Cerrar
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle2}
          open={openModalVideo}
          // overflow="scroll"
          onClose={handleCloseModalVideo}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModalVideo}>
            <div

            // style={{ minHeight: "-webkit-fill-available" }}
            >
              <Box style={{ alignSelf: "center", marginTop: "170px" }}>
                {/* <Box style={{paddingTop:"4rem"}}> */}

                <iframe
                  style={{ align: "middle", alignSelf: "center" }}
                  src={pptRuta}
                  // position="fixed"
                  frameborder="0"
                  width="900px"
                  height="500px"
                  allowfullscreen="true"
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                ></iframe>

                {/* </Box> */}
              </Box>
              <Box
                zIndex="tooltip"
                style={{
                  position: "fixed",
                  width: "20%",
                  left: "65%",
                  top: "80%",
                  height: "3rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="Primary"
                  onClick={handleCloseModalVideo}
                  style={{
                    fontSize: "1rem",
                    color: "#731f1f",
                    backgroundColor: "white",
                  }}
                >
                  <strong>Cerrar</strong>
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </React.Fragment>
  );
}

// export default Bienvenida;
