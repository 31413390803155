import { analytics } from "../../services/firebase";

/************************************************************************************
 * 																		FUNCIONES
 ***********************************************************************************/

/**
 * Método encargado de generar un nuevo registro de evento en Google Analytics.
 * @param {*} evento Nombre del evento.
 * @param {*} usuario Datos del usuario.
 */
export function AddAnalytics(evento, usuario) {
	try {
		let usuarioMap = {
			usuarioCorreo: usuario.email,
			usuarioNombre: usuario.name,
			usuarioContrato: usuario.proyecto,
		}
		analytics.logEvent(evento, usuarioMap);
	} catch (error) {
		console.error(error);
	}
}

/**
 * Método encargado de redireccionar al usuario a una nueva pestaña.
 */
export function RedirectURL(url) {
	window.open(url, "_blank");
};

/************************************************************************************
 * 																		CONSTANTES
 ***********************************************************************************/

/**
 * URL para redireccionar a documento de Criterios ESG.
 * @link Original https://docs.google.com/document/d/1fLgIrtQ8SrBZN3UHh5iJgHdGb_o4RWxyMjRC3VsUKXM/edit
 * @link Copia https://docs.google.com/document/d/1-b6i3Qh0JWUHJ_kTrfq5ykSzyb9_lvyBsVNdd0OgOfU/edit?usp=drive_link
 */
export const CRITERIOS_ESG_URL = "https://docs.google.com/document/d/1fLgIrtQ8SrBZN3UHh5iJgHdGb_o4RWxyMjRC3VsUKXM/preview?usp=embed_googleplus";

/**
 * URL para redireccionar a página de CyD con información de mercados y proyectos.
 */
export const MERCADOS_PROYECTOS_URL = "https://cydingenieria.cl/mercados-proyectos/";

/**
 * URL de PPT en Google Drive con información del organigrama.
 */
export const ORGANIGRAMA_PPT_URL = "https://docs.google.com/presentation/d/e/2PACX-1vS1lrld19j9mRF3k5ihlQSut0eLC3HU6TG26MmSyTJE6I1QMvPZRfkLjbskPf4O2ZretWgBdBjya2ub/embed?start=false&loop=false&delayms=3000&slide=id.g105e7703b9c_1_0";
export const ORGANIGRAMA_PDF_URL =
  "https://storage.googleapis.com/mi-cyd/nosotros/Organigramas/Organigrama%20CyD%20Ingenieria%20Chile%202024.pdf";
export const ORGANIGRAMA_PERU_PDF_URL =
  "https://storage.googleapis.com/mi-cyd/nosotros/Organigramas/Organigrama%20CyD%20Ingenieria%20Peru%202024.pdf";

/**
 * URL de los documentos de Póliticas de CyD, PDF e Imagen pewview.
 * Para la actualización del documento deberán actualizar la versión.
 * @link https://www.syncwithtech.org/google-drive-image-urls/
 * @link https://drive.google.com/uc?id=FILEID
 */
export const POLITICAS_CORPORATIVAS = {
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas de Alcohol y Drogas.
	 */
	ALCOHOL_DROGAS: {
		NAME: "Politica de Alcohol y Drogas",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Alcohol%20y%20Drogas%20CyD%20Ingenier%C3%ADa.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Alcohol%20y%20Drogas.png",
	},
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas de Cumplimiento y Prevención de Delitos.
	*/
	DELITOS: {
		NAME: "Política de Cumplimiento y Prevención de Delitos",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Cumplimiento%20y%20Prevenci%C3%B3n%20de%20Delitos%20CyD%20Ingenier%C3%ADa.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Cumplimiento%20y%20Prevenci%C3%B3n%20de%20Delitos.png",
	},
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas de Inclusión.
	*/
	INCLUSION: {
		NAME: "Políticas de Diversidad, Equidad e Inclusión",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Diversidad%2C%20Equidad%20e%20Inclusi%C3%B3n%20CyD%20Ingenier%C3%ADa.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Diversidad%2C%20Equidad%20e%20Inclusi%C3%B3n.png",
	},
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas de Personas.
	*/
	PERSONAS: {
		NAME: "Política de Personas",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Politica%20de%20Personas%20CyD%20Ingenieria%20VF.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Politica%20de%20Personas%20CyD%20Ingenieria%20VF-1.png",
	},
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas de Redes Sociales.
	*/
	REDES_SOCIALES: {
		NAME: "Política de Redes Sociales",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Redes%20Sociales%20CyD%20Ingenier%C3%ADa.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Redes%20Sociales.png",
	},
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas del Sistema de Gestión Integrado.
	*/
	SGI: {
		NAME: "Política de SGI",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Sistema%20de%20Gesti%C3%B3n%20Integrado%20(SGI)%20CyD%20Ingenier%C3%ADa.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Sistema%20de%20Gesti%C3%B3n%20Integrado%20(SGI).png",
	},
	/**
	 * URL de PDF e Imagen en Google Drive de las Políticas de Sustentabilidad.
	*/
	SUSTENTABILIDAD: {
		NAME: "Política de Sustentabilidad",
		PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Sustentabilidad%20CyD%20Ingenier%C3%ADa.pdf",
		IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Politicas/Pol%C3%ADtica%20de%20Sustentabilidad.png",
	},
}

/**
 * URLs de las presentaciones corporativas, en Español e Inglés.
 */
export const PRESENTACIONES_CORPORATIVAS = {
  CYD_INGENIERIA: {
    ESPAÑOL:
      "https://storage.googleapis.com/mi-cyd/nosotros/Presentacion%20Corportativa/Presentaci%C3%B3n%20Corporativa%20CyD%20Ingenier%C3%ADa.pdf",
    INGLES:
      "https://storage.googleapis.com/mi-cyd/nosotros/Presentacion%20Corportativa/Corporate%20Presentation%20CyD%20Ingenieria.pdf",
  },
  CYD_TECNOLOGIA: {
    ESPAÑOL:
      "https://storage.googleapis.com/mi-cyd/nosotros/Presentacion%20Corportativa/Presentaci%C3%B3n%20corporativa%20CyD%20Tecnolog%C3%ADa.pdf",
    INGLES:
      "https://storage.googleapis.com/mi-cyd/nosotros/Presentacion%20Corportativa/General%20Presentation%20CyD%20Tecnolog%C3%ADa.pdf",
  },
};

/**
 * URL para redireccionar a documento de Criterios ESG.
 * @link https://drive.google.com/drive/folders/1vPwKBO88J-56Ck1pOy5rsp9DbClQa1vg
 */
export const CRITERIOS_ESG = {
	NAME: "Criterios ESG",
	PDF_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Criterios%20ESG/2023-05%20Criterios%20ESG%20en%20CyD%20Ingenier%C3%ADa%20v1%20(2)%20(1).pdf",
	IMAGE_URL: "https://storage.googleapis.com/mi-cyd/nosotros/Criterios%20ESG/ESG.JPG",
}

/**
 * Variables de Analytics para monitorear ingresos.
 */
export const ANALYTICS = {
	/**
	 * Variable de las Políticas de Alcohol y Drogas.
	 */
	ALCOHOL_DROGAS: "nosotros_politicas_alcohol_y_drogas",
	/**
	 * Variable de las Políticas de Cumplimiento y Prevención de Delitos.
	 */
	DELITOS: "nosotros_politicas_prevención_delito",
	/**
	 * Variable de las Políticas de Inclusión.
	 */
	INCLUSION: "nosotros_politicas_inclusión",
	/**
	 * Variable de las Políticas de Personas.
	 */
	PERSONAS: "nosotros_politicas_personas",
	/**
	 * Variable de las Políticas de Redes Sociales.
	 */
	REDES_SOCIALES: "nosotros_politicas_RRSS",
	/**
	 * Variable de las Políticas del Sistema de Gestión Integrado.
	 */
	SGI: "nosotros_politicas_SGI",
	/**
	 * Variable de las Políticas de Sustentabilidad.
	 */
	SUSTENTABILIDAD: "nosotros_politicas_sustentabilidad",
	/**
	 * Variable de los Mercados y Proyectos de CyD.
	 */
	MERCADOS_PROYECTOS: "ver_nosotros_mercados_y_proyectos",
	/**
	 * Variable del Organigrama de CyD.
	 */
	ORGANIGRAMA: "ver_nosotros_organigrama_pdf",
	/**
	 * Variable del Organigrama de CyD.
	 */
	ORGANIGRAMA_PERU: "ver_nosotros_organigrama_peru_pdf",
	/**
	 * Variable del accordion de Políticas de CyD.
	 */
	ACCORDION_POLITICAS_CYD: "ver_nosotros_políticas_corporativas_acordion",
	/**
	 * Variable del accordion de Presentación de CyD.
	 */
	ACCORDION_PRESENTACION_CYD: "ver_nosotros_presentación_corporativa_acordion",
	/**
	 * Variable del accordion de Premios de CyD.
	 */
	ACCORDION_PREMIOS: "ver_nosotros_premios_logros_acordion",
	/**
	 * Variable de la Galería de imágenes de CyD.
	 */
	ACCORDION_GALERIA: "ver_nosotros_galeria_acordion",
}